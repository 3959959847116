<template>
  <div class="card">
    <router-link :to="{ name: 'Meal', params: {
        id: meal.idMeal
      }
    }">
      <img class="thumb" :src="meal.strMealThumb" >
      <h3>{{ meal.strMeal }}</h3>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'MealCard',
  props: {
    meal: {
      type: Object
    }
  }
}
</script>

<style scoped>
.card {
  max-width: 200px;
  align-items: start;
}
img.thumb {
  width:100%;
}
h3 {
  min-height: 50px;
}
</style>
