<template>
  <div class="categorie">
    <div class="header">
      <h2>Recettes commençants avec la lettre <span class="redtext">{{ lettre.toUpperCase() }}</span></h2>
    </div>
    <div v-if="meals === 0">
      <h3>Aucune recette ne commence par la lettre <span class="redtext">{{ lettre.toUpperCase() }}</span> :( ...</h3>
    </div>
    <div v-else class="card-container">
      <div v-for="c in meals" :key="c.id">
        <MealCard :meal="c"/>
      </div>
    </div>
  </div>
</template>

<script>
import MealCard from '@/components/MealCard.vue'

export default {
  name: 'Lettre',
  components: {
    MealCard
  },
  props: {
    lettre: {
      type: String
    }
  },
  data () {
    return {
      meals: null
    }
  },
  mounted () {
    if (this.lettre.length !== 1 || !this.lettre.match(/[a-z]/i)) this.$router.push({ path: '/404' })
    fetch('https://www.themealdb.com/api/json/v1/1/search.php?f=' + this.lettre)
      .then(response => response.json())
      .then(response => {
        if (response.meals != null) this.meals = response.meals.sort((a, b) => (a.strMeal.toLowerCase() > b.strMeal.toLowerCase()) ? 1 : ((b.strMeal.toLowerCase() > a.strMeal.toLowerCase()) ? -1 : 0))
        else this.meals = 0
      })
  }
}
</script>

<style scoped>
.header {
  margin-top: 50px;
}
.card {
  max-width: 200px;
  align-items: start;
}
img.thumb {
  width:100%;
}
h3 {
  min-height: 50px;
}
</style>
